import React from 'react';
import InteractiveTerminal from './components/InteractiveTerminal';
import './App.css';

// This is the main component of our React application
// It serves as the layout for our entire personal homepage
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Add a header/navigation here if needed */}
        <h1>Tarek's Interactive Resume</h1>
      </header>
      <main>
        <section>
          <h2>Interactive Resume Terminal</h2>
          <InteractiveTerminal />
        </section>
        {/* Add other sections/projects here */}
      </main>
      <footer>
        {/* Add a footer here if needed */}
        <p>&copy; {new Date().getFullYear()} Tarek. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;