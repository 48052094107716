// This file contains the data structure for the virtual file system
// It represents the content of the interactive resume

export const fileSystem = {
    '/': {
        type: 'dir',
        contents: ['skills', 'experience', 'education', 'projects', 'contact.txt']
    },
    '/skills': {
        type: 'dir',
        contents: ['technical.txt', 'soft.txt']
    },
    '/skills/technical.txt': {
        type: 'file',
        content: 'JavaScript, React, Node.js, Python, SQL'
    },
    '/skills/soft.txt': {
        type: 'file',
        content: 'Communication, Teamwork, Problem-solving, Adaptability'
    },
    '/experience': {
        type: 'dir',
        contents: ['job1.txt', 'job2.txt', 'job3.txt']
    },
    '/education': {
        type: 'dir',
        contents: ['harvard.txt', 'stjohns.txt']
    },
    '/projects': {
        type: 'dir',
        contents: ['bose_subreddit.txt', 'statistical_simulation.txt', 'trademark_generator.txt', 'pentesting_homelab.txt']
    },
    '/contact.txt': {
        type: 'file',
        content: 'Email: tarek_@alumni.harvard.edu\nPhone: (774) 262-5551\nLocation: Winchester, MA'
    },
    // Add more files and directories as needed
    // Example:
    // '/skills/technical.txt': {
    //     type: 'file',
    //     content: 'JavaScript, React, Node.js, Python, SQL, ...'
    // },
};