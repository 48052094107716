import React, { useState, useEffect, useRef, useCallback } from 'react';
import { fileSystem } from '../data/fileSystem';

// This component contains the logic and UI for the interactive terminal
const InteractiveTerminal = () => {
    // State declarations for managing terminal state
    const [input, setInput] = useState('');
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [output, setOutput] = useState(['Welcome to Tarek\'s interactive resume! Type "help" for available commands.']);
    const [currentDir, setCurrentDir] = useState('/');
    
    // Refs for managing focus and scrolling
    const inputRef = useRef(null);
    const outputRef = useRef(null);

    // Effect hooks for focus and scrolling behavior
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    useEffect(() => {
        outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }, [output]);

    // Helper function for path resolution
    const getAbsolutePath = useCallback((path) => {
        if (path.startsWith('/')) return path;
        return `${currentDir}${currentDir === '/' ? '' : '/'}${path}`;
    }, [currentDir]);

    // Function to handle command execution
    const handleCommand = useCallback((cmd) => {
        const [command, ...args] = cmd.trim().split(' ');
        let newOutput = [...output, `$ ${cmd}`];

        switch (command.toLowerCase()) {
            case 'help':
                newOutput.push('Available commands: help, ls, cd, cat');
                break;
            case 'ls':
                const dirContent = fileSystem[currentDir]?.contents || [];
                newOutput.push(dirContent.join('  '));
                break;
            case 'cd':
                const newPath = getAbsolutePath(args[0] || '/');
                if (fileSystem[newPath] && fileSystem[newPath].type === 'dir') {
                    setCurrentDir(newPath);
                    newOutput.push(`Changed directory to ${newPath}`);
                } else {
                    newOutput.push(`cd: ${args[0]}: No such directory`);
                }
                break;
            case 'cat':
                const filePath = getAbsolutePath(args[0]);
                if (fileSystem[filePath] && fileSystem[filePath].type === 'file') {
                    newOutput.push(fileSystem[filePath].content);
                } else {
                    newOutput.push(`cat: ${args[0]}: No such file`);
                }
                break;
            default:
                newOutput.push(`${command}: command not found`);
        }

        setOutput(newOutput);
        setHistory([...history, cmd]);
        setHistoryIndex(history.length + 1);
    }, [currentDir, getAbsolutePath, history, output]);

    // Function to handle form submission
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (input.trim()) {
            handleCommand(input);
            setInput('');
        }
    }, [input, handleCommand]);

    // Function to handle keyboard navigation
    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHistoryIndex(prev => Math.max(0, prev - 1));
            setInput(history[historyIndex] || '');
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHistoryIndex(prev => Math.min(history.length, prev + 1));
            setInput(history[historyIndex] || '');
        }
    }, [history, historyIndex]);

    // The component's render method, defining the UI
    return (
        <div className="fixed inset-0 bg-black text-green-400 font-mono p-4 flex flex-col">
            <div ref={outputRef} className="flex-grow overflow-y-auto mb-4 whitespace-pre-wrap">
                {output.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="flex">
                <span className="mr-2">user@tarek-resume:{currentDir}$</span>
                <input
                    ref={inputRef}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-grow bg-transparent text-green-400 focus:outline-none"
                />
            </form>
        </div>
    );
};

export default InteractiveTerminal;